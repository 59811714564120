<template>
  <div
    :class="[
      'markets-list',
      { 'markets-list--horizontal': isHorizontal },
      { 'markets-list--singular': isSingular },
    ]"
  >
    <div class="markets-list__grid">
      <div class="markets-list__grid-column">
        <div class="markets-list__grid-cell markets-list__grid-cell--header">
          {{ markets[0]?.paramHeaderDisplay }}
        </div>
      </div>

      <div
        v-if="markets[0]?.markets?.[0]?.paramHeaderDisplay"
        class="markets-list__grid-column"
      >
        <div class="markets-list__grid-cell markets-list__grid-cell--header">
          {{ markets[0]?.markets?.[0]?.paramHeaderDisplay }}
        </div>
      </div>

      <div
        v-if="!isNil(markets[0]?.markets?.[0]?.marketType?.params?.LINE) || !isNil(markets[0]?.markets?.[0]?.markets?.[0]?.marketType?.params?.LINE)"
        class="markets-list__grid-column"
      >
        <div class="markets-list__grid-cell markets-list__grid-cell--header">
          Line
        </div>
      </div>

      <div
        v-for="(selection, selectionIndex) in selections"
        :key="selectionIndex"
        class="markets-list__grid-column"
      >
        <div
          class="markets-list__grid-cell markets-list__grid-cell--header"
          :title="mapSelectionName(selection)"
        >
          <span>{{ mapSelectionName(selection) }}</span>
        </div>
      </div>
    </div>

    <div
      v-for="(group, groupIndex) in markets"
      :key="groupIndex"
      class="markets-list__grid"
    >
      <div class="markets-list__grid-column">
        <div
          class="markets-list__grid-cell markets-list__grid-cell--bordered"
        >
          <span>{{ group.paramDisplay }}</span>
        </div>
      </div>

      <div
        v-if="group.markets?.[0]?.paramDisplay"
        class="markets-list__grid-column"
      >
        <div
          v-for="(subGroup, subGroupIndex) in group.markets"
          :key="subGroupIndex"
        >
          <div
            v-if="!subGroup.markets?.length"
            class="markets-list__grid-cell"
          >
            <span>{{ subGroup.paramDisplay }}</span>
          </div>
          <div
            v-for="(subMarket) in subGroup.markets"
            :key="subMarket.marketId"
            :class="['markets-list__grid-cell']"
          >
            <span>{{ subGroup.paramDisplay }}</span>
          </div>
        </div>
      </div>
      <!-- if it has subgroup -->
      <div
        v-if="group.markets?.[0]?.paramDisplay && !isNil(group.markets?.[0].markets?.[0]?.marketType?.params?.LINE)"
        class="markets-list__grid-column"
      >
        <div
          v-for="(subGroup, subGroupIndex) in group.markets"
          :key="subGroupIndex"
        >
          <div
            v-for="subMarket in subGroup.markets"
            :key="subMarket.marketId"
            :class="['markets-list__grid-cell', { 'suspended': subMarket.offeringStatus === 'SUSPENDED' }]"
          >
            {{ subMarket.marketType.params.LINE }}
          </div>
        </div>
      </div>
      <!-- if it has only 1 main group -->
      <div
        v-if="!isNil(group.markets?.[0]?.marketType?.params?.LINE)"
        class="markets-list__grid-column"
      >
        <div
          v-for="(market, marketIndex) in group.markets"
          :key="marketIndex"
          :class="['markets-list__grid-cell', { 'suspended': market.offeringStatus === 'SUSPENDED' }]"
        >
          {{ market.marketType.params.LINE }}
        </div>
      </div>

      <div
        v-for="(selection, selectionIndex) in selections"
        :key="selectionIndex"
        class="markets-list__grid-column"
      >
        <div
          v-for="(market, marketIndex) in group.markets"
          :key="marketIndex"
        >
          <div
            :class="['markets-list__grid-cell', { 'suspended': market.offeringStatus === 'SUSPENDED' }]"
            v-if="!market.markets?.length"
          >
            {{ getPriceBySelection(market.selections, selection, oddsFormat) }}
          </div>
          <div
            :class="['markets-list__grid-cell', { 'suspended': subMarket.offeringStatus === 'SUSPENDED' }]"
            v-for="subMarket in market.markets"
            :key="subMarket.marketId"
          >
            {{ getPriceBySelection(subMarket.selections, selection, oddsFormat) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { isNil } from 'lodash';
import MarketMapper from '@/services/helpers/market-mapper';
import { getPriceBySelection, setTeamNames } from './utils';

export default {
  props: {
    markets: {
      type: Array,
      required: true,
    },
    selections: {
      type: Array,
      required: true,
    },
    isHorizontal: {
      type: Boolean,
      default: false,
    },
    isSingular: {
      type: Boolean,
      default: false,
    },
    sport: {
      type: Object,
      required: true,
    },
    teams: {
      type: Object,
      default: null,
    },
    isUsaView: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const oddsFormat = computed(() => store.getters.selectedOddFormat?.name);
    const homeTeam = computed(() => props.teams?.home);
    const awayTeam = computed(() => props.teams?.away);

    const mapSelectionName = (selection) => {
      const selectionName = MarketMapper.selectionName(selection, props.sport, props.isUsaView);
      return setTeamNames(selectionName, homeTeam.value, awayTeam.value);
    };

    return {
      homeTeam,
      awayTeam,
      mapSelectionName,
      getPriceBySelection,
      oddsFormat,
      isNil,
    };
  },
};
</script>
