<template>
  <div
    class="pricing-details-section"
    v-if="marketDisplayConfiguration.grouped"
  >
    <div
      :class="['pricing-details-section__head',
               { 'suspended': allMarketsSuspended }]"
    >
      <div
        class="pricing-details-section__info"
        v-if="sectionFormattedMarkets"
      >
        <span class="pricing-details-section__title">
          {{ title }}
        </span>
        <span
          :class="[
            'pricing-details-section__status',
            { 'active': resultingStatus.type === RESULTING_STATUS_TYPES.NOT_RESULTED},
            { 'resulted': resultingStatus.type === RESULTING_STATUS_TYPES.RESULTED},
            { 'pending-resulting': resultingStatus.type === RESULTING_STATUS_TYPES.PENDING_RESULTING},
            /* { 'incorect-resulting': resultingStatus.type === RESULTING_STATUS_TYPES.RESULT_AMENDED}, */
            { 'suspended': allMarketsSuspended},
          ]"
        >
          <Icon
            v-if="resultingStatus.icon"
            :name="resultingStatus.icon"
            :class="['status-icon', { 'rotate': resultingStatus.type === RESULTING_STATUS_TYPES.PENDING_RESULTING }]"
          />
          {{ resultingStatus.label }}
        </span>
      </div>
      <div
        v-if="!eventSuspended"
        class="pricing-details-section__action"
      >
        <Button
          v-if="!allMarketsSuspended"
          variant="danger-secondary"
          :loading="isSuspendMarketTypeActionLoading"
          @click="changeMarketSuspension('batch-suspend')"
        >
          Suspend market type
        </Button>
        <Button
          v-else
          variant="secondary"
          :loading="isSuspendMarketTypeActionLoading"
          @click="changeMarketSuspension('batch-unsuspend')"
        >
          Unsuspend market type
        </Button>
      </div>
    </div>

    <div
      :class="[
        'pricing-details-section__body_footer',
        { 'accordion': showAllLinesButton },
      ]"
    >
      <div class="pricing-details-section__body">
        <GroupMarketsList
          v-if="marketDisplayConfiguration.groupView?.groupedParams?.length"
          :markets="sectionFormattedMarkets"
          :selections="selections"
          :is-horizontal="isHorizontal"
          :is-singular="isSingular"
          :sport="sport"
          :teams="teams"
          :is-usa-view="isUsaView"
        />
        <DefaultMarketsList
          v-else
          :markets="sectionFormattedMarkets"
          :selections="selections"
          :is-horizontal="isHorizontal"
          :sport="sport"
          :teams="teams"
          :is-usa-view="isUsaView"
        />
      </div>

      <div
        v-if="showAllLinesButton"
        class="pricing-details-section__footer"
      >
        <Button
          variant="tertiary"
          @click="() => { showAllLines =! showAllLines; }"
          :icon="showAllLines ? 'chevron-up' : 'chevron-down'"
          icon-right
        >
          {{ showAllLines ? 'Hide all lines' : 'Show all lines' }}
        </Button>
      </div>
    </div>
  </div>
  <div
    class="pricing-details-section"
    v-else
    v-for="market in sectionFormattedMarkets"
    :key="market.marketId"
  >
    <div
      :class="['pricing-details-section__head',
               { 'suspended': market.offeringStatus === 'SUSPENDED' }]"
    >
      <div class="pricing-details-section__info">
        <span class="pricing-details-section__title">
          {{ market.displayName }}
        </span>
        <span
          :class="[
            'pricing-details-section__status',
            { 'active': market.resultingStatus === RESULTING_STATUS_TYPES.NOT_RESULTED},
            { 'resulted': market.resultingStatus === RESULTING_STATUS_TYPES.RESULTED},
            { 'pending-resulting': market.resultingStatus === RESULTING_STATUS_TYPES.PENDING_RESULTING},
            /* { 'incorect-resulting': market.resultingStatus === RESULTING_STATUS_TYPES.RESULT_AMENDED}, */
            { 'suspended': market.offeringStatus === 'SUSPENDED'},
          ]"
        >
          <Icon
            v-if="market.resultingStatus === RESULTING_STATUS_TYPES.PENDING_RESULTING"
            :name="'loading'"
            :class="['status-icon', { 'rotate': market.resultingStatus === RESULTING_STATUS_TYPES.PENDING_RESULTING }]"
          />
          {{ displayMarketResultingLabel(market.resultingStatus) }}
        </span>
      </div>
      <div
        v-if="!eventSuspended"
        class="pricing-details-section__action"
      >
        <Button
          v-if="!allMarketsSuspended"
          variant="danger-secondary"
          :loading="isSuspendMarketTypeActionLoading"
          @click="changeMarketSuspension('batch-suspend')"
        >
          Suspend market type
        </Button>
        <Button
          v-else
          variant="secondary"
          :loading="isSuspendMarketTypeActionLoading"
          @click="changeMarketSuspension('batch-unsuspend')"
        >
          Unsuspend market type
        </Button>
      </div>
    </div>

    <div class="pricing-details-section__body_footer">
      <div class="pricing-details-section__body">
        <DefaultMarketsList
          :markets="[market]"
          :selections="selections"
          :is-horizontal="isHorizontal"
          :sport="sport"
          :teams="teams"
          :is-usa-view="isUsaView"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  map, find, each, includes,
  isNil, concat, groupBy, orderBy, assign,
} from 'lodash';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import MarketMapper from '@/services/helpers/market-mapper';
import Button from '@/components/common/Button';
import GroupMarketsList from './GroupMarketsList';
import DefaultMarketsList from './DefaultMarketsList';
import Icon from '@/components/common/Icon';

const {
  getResultingStatus,
  RESULTING_STATUS_TYPES,
} = MarketMapper;

const HORIZONTAL_MARKETS_COUNT_THRESHOLD = 4;

export default {
  components: {
    Button,
    GroupMarketsList,
    DefaultMarketsList,
    Icon,
  },
  props: {
    markets: {
      type: Array,
      required: true,
    },
    marketDisplayConfiguration: {
      type: Object,
      required: true,
    },
    sport: {
      type: Object,
      required: true,
    },
    competition: {
      type: Object,
      required: true,
    },
    teams: {
      type: Object,
      default: null,
    },
    isUsaView: {
      type: Boolean,
      required: true,
    },
    allMarketParameters: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const showAllLines = ref(false);

    const marketCodeSectionInternalMarkets = computed(() => MarketMapper.sortSectionMarkets(map(props.markets,
      (market) => {
        if (market.marketType.params.PLAYER_INDEX) {
          const playerdisplayNameSplit = market.marketSummary.playerName.split(', ');
          const playerDisplayName = `${playerdisplayNameSplit[1]} ${playerdisplayNameSplit[0]}`;
          return {
            ...market,
            playerDisplayName,
            selections: MarketMapper.sortSelections({ selections: market.selections, isUSAView: props.isUsaView }),
          };
        }
        return {
          ...market,
          selections: MarketMapper.sortSelections({ selections: market.selections, isUSAView: props.isUsaView }),
        };
      }), props.isUsaView));
    const groupParamDisplay = (param, paramValue, markets) => {
      if (param === 'TEAM') {
        const team = props.teams[paramValue === 0 ? 'home' : 'away'];
        return {
          paramDisplay: team?.name || 'N/A team',
          paramHeaderDisplay: find(props.allMarketParameters, (parameter) => parameter.code === param)?.name || 'Team',
        };
      }
      if (param === 'PLAYER_INDEX') {
        return {
          paramDisplay: markets[0]?.playerDisplayName || markets[0]?.marketSummary?.playerName || 'N/A player',
          paramHeaderDisplay: find(props.allMarketParameters, (parameter) => parameter.code === param)?.name || 'Player',
        };
      }
      const paramName = find(props.allMarketParameters, (parameter) => parameter.code === param)?.name || param;
      return {
        paramDisplay: props.marketDisplayConfiguration.parameters[param]?.type === 'ORDINAL'
          ? `${paramValue}${MarketMapper.mapNumberSuffix(paramValue.toString())} ${paramName}`
          : `${paramName} ${paramValue}`,
        paramHeaderDisplay: paramName,
      };
    };

    const showAllLinesButton = computed(() => {
      let flag = false;
      if (props.marketDisplayConfiguration.grouped) {
        // grouped but doesnt have set grouped params ---> exception
        if (!props.marketDisplayConfiguration.groupView?.groupedParams.length) {
          if (marketCodeSectionInternalMarkets.value?.length > 1 && marketCodeSectionInternalMarkets.value?.[0]?.marketType?.params.LINE) {
            flag = true;
            return flag;
          }
        }
        const mainGroupedParam = props.marketDisplayConfiguration.groupView?.groupedParams[0];
        const subGroupedParam = props.marketDisplayConfiguration.groupView?.groupedParams[1];
        const mainGroup = `marketType.params.${mainGroupedParam}`;
        const subGroup = `marketType.params.${subGroupedParam}`;
        map(groupBy(marketCodeSectionInternalMarkets.value, mainGroup), (groupMarkets) => {
          let filteredSubGroupMarkets = [];
          if (subGroupedParam) {
            filteredSubGroupMarkets = map(groupBy(groupMarkets, subGroup), (subgroupMarkets, subkey) => ({
              paramValue: +subkey,
              param: subGroupedParam,
              paramDisplay: groupParamDisplay(subGroupedParam, +subkey, subgroupMarkets).paramDisplay,
              paramHeaderDisplay: groupParamDisplay(subGroupedParam, +subkey, subgroupMarkets).paramHeaderDisplay,
              markets: subgroupMarkets,
            }));
            if (subGroupedParam === 'TEAM' && props.isUsaView) {
              filteredSubGroupMarkets = orderBy(filteredSubGroupMarkets, ['paramValue'], ['desc']);
            }
            if (subGroupedParam === 'PLAYER_INDEX') {
              filteredSubGroupMarkets = orderBy(filteredSubGroupMarkets, 'paramDisplay');
            }
          }
          if (!subGroupedParam && groupMarkets?.length > 1 && groupMarkets[0]?.marketType?.params.LINE) {
            flag = true;
            return groupMarkets;
          }
          each(filteredSubGroupMarkets, (group) => {
            if (group?.markets?.length > 1 && !isNil(group?.markets[0]?.marketType.params.LINE)) {
              flag = true;
              return groupMarkets;
            }
            return groupMarkets;
          });
          return groupMarkets;
        });
      }
      return flag;
    });
    const sectionFormattedMarkets = computed(() => {
      // grouped market
      if (props.marketDisplayConfiguration.grouped) {
        // grouped but doesnt have set grouped params ---> exception
        if (!props.marketDisplayConfiguration.groupView?.groupedParams.length) {
          return (showAllLinesButton.value && !showAllLines.value) ? [marketCodeSectionInternalMarkets.value[0]] : marketCodeSectionInternalMarkets.value;
        }
        const mainGroupedParam = props.marketDisplayConfiguration.groupView?.groupedParams[0];
        const subGroupedParam = props.marketDisplayConfiguration.groupView?.groupedParams[1];
        const mainGroup = `marketType.params.${mainGroupedParam}`;
        const subGroup = `marketType.params.${subGroupedParam}`;
        let mainGroupMarkets = map(groupBy(marketCodeSectionInternalMarkets.value, mainGroup),
          (groupMarkets, key) => {
            let filteredSubGroupMarkets = [];
            if (subGroupedParam) {
              filteredSubGroupMarkets = map(groupBy(groupMarkets, subGroup), (subgroupMarkets, subkey) => ({
                paramValue: +subkey,
                param: subGroupedParam,
                paramDisplay: groupParamDisplay(subGroupedParam, +subkey, subgroupMarkets).paramDisplay,
                paramHeaderDisplay: groupParamDisplay(subGroupedParam, +subkey, subgroupMarkets).paramHeaderDisplay,
                markets: subgroupMarkets,
              }));
              if (subGroupedParam === 'TEAM' && props.isUsaView) {
                filteredSubGroupMarkets = orderBy(filteredSubGroupMarkets, ['paramValue'], ['desc']);
              }
              if (subGroupedParam === 'PLAYER_INDEX') {
                filteredSubGroupMarkets = orderBy(filteredSubGroupMarkets, 'paramDisplay');
              }
            }

            const displayedGroupMarkets = (!showAllLines.value && showAllLinesButton.value) ? [groupMarkets?.[0]] : groupMarkets;
            each(filteredSubGroupMarkets, (group) => {
              if (!showAllLines.value && showAllLinesButton.value) {
                assign(group, { ...group, markets: [group.markets[0]] });
              }
            });
            return {
              markets: subGroupedParam ? filteredSubGroupMarkets : displayedGroupMarkets,
              param: mainGroupedParam,
              paramDisplay: groupParamDisplay(mainGroupedParam, +key, displayedGroupMarkets).paramDisplay,
              paramHeaderDisplay: groupParamDisplay(mainGroupedParam, +key, displayedGroupMarkets).paramHeaderDisplay,
              paramValue: +key,
            };
          });

        if (mainGroupedParam === 'TEAM' && props.isUsaView) {
          mainGroupMarkets = orderBy(mainGroupMarkets, ['paramValue'], ['desc']);
        }
        if (mainGroupedParam === 'PLAYER_INDEX') {
          mainGroupMarkets = orderBy(mainGroupMarkets, 'paramDisplay');
        }
        return mainGroupMarkets;
      }
      // ungrouped market
      const startingBracketIndexes = [];
      const endingBracketIndexes = [];

      const { longNamePattern } = props.marketDisplayConfiguration;
      for (let i = 0; i < longNamePattern?.length; i += 1) {
        if (longNamePattern[i] === '{') startingBracketIndexes.push(i);
        if (longNamePattern[i] === '}') endingBracketIndexes.push(i);
      }

      const paramsToReplace = [];

      for (let i = 0; i < startingBracketIndexes.length; i += 1) {
        paramsToReplace.push(longNamePattern.slice(startingBracketIndexes[i] + 1, endingBracketIndexes[i]));
      }
      return map(marketCodeSectionInternalMarkets.value, (market) => {
        let displayName = longNamePattern;
        if (!paramsToReplace.length) {
          return {
            ...market,
            displayName,
          };
        }
        each(paramsToReplace, (param) => {
          if (param === 'TEAM') {
            const team = props.teams[market.marketType.params.TEAM === 0 ? 'home' : 'away'];
            const teamName = includes(paramsToReplace, 'PLAYER_INDEX') ? team?.shortName : team?.name;
            displayName = displayName.replace('TEAM', teamName);
            displayName = displayName.replace(/{|}/g, '');
            return;
          }
          if (param === 'PLAYER_INDEX') {
            displayName = displayName.replace('PLAYER_INDEX', market.playerDisplayName || market.marketSummary?.playerName || 'N/A');
            displayName = displayName.replace(/{|}/g, '');
            return;
          }
          if (param === 'PITCHER_PLAYER_INDEX' || param === 'BATTER_PLAYER_INDEX') {
            const playerIndex = market.marketType.params[param];
            const playerName = market.marketSummary?.players?.[playerIndex]?.playerName;
            const playerdisplayNameSplit = playerName?.split(', ');
            const playerDisplayName = playerdisplayNameSplit
              ? `${playerdisplayNameSplit[1]} ${playerdisplayNameSplit[0]}`
              : '';
            displayName = displayName.replace(param, playerDisplayName || 'N/A');
            displayName = displayName.replace(/{|}/g, '');
            return;
          }
          if (param === 'IS_TOP_INNING') {
            const isTopInningValue = market.marketType.params.IS_TOP_INNING === 0 ? 'Bottom' : 'Top';
            displayName = displayName.replace('IS_TOP_INNING', isTopInningValue);
            displayName = displayName.replace(/{|}/g, '');
            return;
          }
          const valueToReplace = market.marketType.params[param];
          const numberSuffix = props.marketDisplayConfiguration.parameters[param]?.type === 'ORDINAL' ? MarketMapper.mapNumberSuffix(valueToReplace.toString()) : '';
          displayName = displayName.replace(param, `${valueToReplace}${numberSuffix}`);
          displayName = displayName.replace(/{|}/g, '');
        });
        return {
          ...market,
          displayName,
        };
      });
    });

    const title = computed(() => {
      if (props.marketDisplayConfiguration?.grouped) return props.marketDisplayConfiguration?.groupView?.longNamePattern;
      return sectionFormattedMarkets.value?.[0]?.displayName;
    });

    const allMarketsSuspended = computed(() => {
      if (!props.markets?.[0]?.markets) {
        return !find(props.markets, (market) => market.offeringStatus !== 'SUSPENDED');
      }
      let notSuspendedMarket = false;
      each(props.markets, (marketGroup) => {
        each(marketGroup.markets, (market) => {
          if (market.offeringStatus !== 'SUSPENDED') notSuspendedMarket = true;
        });
      });
      return !notSuspendedMarket;
    });

    const selections = computed(() => {
      if (sectionFormattedMarkets.value?.[0]?.markets?.[0]?.markets?.length) return sectionFormattedMarkets.value?.[0]?.markets?.[0]?.markets[0].selections || [];
      if (sectionFormattedMarkets.value?.[0]?.markets?.length) return sectionFormattedMarkets.value[0]?.markets?.[0]?.selections || [];
      return sectionFormattedMarkets.value[0]?.selections || [];
    });
    const isHorizontal = computed(() => selections.value.length > HORIZONTAL_MARKETS_COUNT_THRESHOLD);
    const isSingular = computed(() => marketCodeSectionInternalMarkets.value?.length === 1 || (showAllLinesButton.value && !showAllLines.value));

    const getMarketProperty = (market, marketProperty) => {
      const dataInsideMarketObj = market.markets && market.markets.length;
      const data = dataInsideMarketObj ? market.markets[0] : market;

      return data[marketProperty];
    };

    const selectedEventId = computed(() => store.getters.event.id);
    const eventSuspended = computed(() => store.getters.event.isSuspended);
    const marketTypeSuspended = computed(() => {
      const offeringStatus = !props.markets?.[0]?.markets
        ? props.markets?.[0]?.offeringStatus
        : props.markets?.[0]?.markets[0]?.offeringStatus;
      return offeringStatus === 'SUSPENDED';
    });

    const resultingStatus = computed(() => {
      if (!props.markets.length) return null;
      const marketCode = getMarketProperty(props.markets[0], 'marketCode');
      const marketTypeResultingStatuses = map(props.markets, (market) => getMarketProperty(market, 'resultingStatus'));
      return getResultingStatus({
        marketCode,
        eventSuspended: eventSuspended.value,
        marketTypeSuspended: allMarketsSuspended.value,
        resultingStatusArr: marketTypeResultingStatuses,
      });
    });
    const isSuspendMarketTypeActionLoading = ref(false);

    const changeMarketSuspension = (operation) => {
      if (isSuspendMarketTypeActionLoading.value) return;
      let marketIds = [];
      if (!props.markets[0]?.markets) {
        marketIds = map(props.markets, (market) => market.marketId);
      } else {
        each(props.markets, (marketGroup) => {
          marketIds = concat(marketIds, map(marketGroup.markets, (market) => market.marketId));
        });
      }
      isSuspendMarketTypeActionLoading.value = true;
      store.dispatch('batchUpdateMarketSuspensionByMarketId', {
        eventId: selectedEventId.value,
        marketIds,
        operation,
      })
        .then(() => {
          isSuspendMarketTypeActionLoading.value = false;
        })
        .catch((error) => {
          isSuspendMarketTypeActionLoading.value = false;
          console.log(error);
        });
    };

    const displayMarketResultingLabel = (status) => {
      if (status === RESULTING_STATUS_TYPES.NOT_RESULTED) return 'Active';
      if (status === RESULTING_STATUS_TYPES.RESULTED) return 'Resulted';
      if (status === RESULTING_STATUS_TYPES.PENDING_RESULTING) return 'Pending resulting';
      if (status === RESULTING_STATUS_TYPES.RESULT_AMENDED) return '';
      return 'Active';
    };

    return {
      title,
      selections,
      isHorizontal,
      isSingular,
      resultingStatus,
      RESULTING_STATUS_TYPES,
      changeMarketSuspension,
      isSuspendMarketTypeActionLoading,
      marketTypeSuspended,
      eventSuspended,
      allMarketsSuspended,
      isNil,
      sectionFormattedMarkets,
      showAllLines,
      showAllLinesButton,
      displayMarketResultingLabel,
    };
  },
};
</script>

<style lang="scss">
.pricing-details-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  .pricing-details-section__head {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background-color: $gray300;

    &.suspended {
      background-color: $error50;
    }

    .pricing-details-section__info {

      .pricing-details-section__title {
        display: block;
        font-size: 16px;
        line-height: 19px;
      }

      .pricing-details-section__status {
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;

        &.active, &.pending-resulting {
          color: $gray700;
        }

        &.resulted {
          color: $primary500;
        }

        &.incorect-resulting, &.suspended {
          color: $error500;
        }

        .status-icon {
          margin-right: 5px;

          &.rotate {
            animation: rotate linear 1.25s infinite;
          }
        }
      }
    }
  }

  .pricing-details-section__body_footer {
    position: relative;

    &.accordion::before {
      bottom: 40px;
    }
  }

  .pricing-details-section__body {

    .markets-list {
      display: flex;
      flex-direction: column;

      .markets-list__grid {
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        .markets-list__grid-column {
          flex: 1;
          display: flex;
          flex-direction: column;
          min-width: 0;

          .markets-list__grid-cell {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            border: 0.5px solid #F0F0F0;
            border-left: none;
            border-right: none;
            min-height: 38px;

            &.markets-list__grid-cell--bordered {
              border-right: 0.5px solid #F0F0F0;
            }

            &.markets-list__grid-cell--header {
              background: #FAFAFA;
              color: #6D6D6D;
              min-height: 33px;
              flex-grow: 1;
              border: none;
              text-transform: capitalize;
              padding: 0 12px;
            }
            span {
              width: 100%;
              display: inline-block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &.suspended {
              opacity: 0.3;
            }
          }
        }

        &:last-child {
          .markets-list__grid-cell {
            border-bottom: none;
          }
        }
      }

      &.markets-list--horizontal {
        flex-direction: row;

        .markets-list__grid {
          flex-direction: column;
          max-width: 50%;
          .markets-list__grid-column {
            width: 100%;
          }
        }
      }

      &.markets-list--singular {
        .markets-list__grid-cell {
          &.markets-list__grid-cell--bordered {
            border-right: none !important;
          }
        }
      }
    }
  }

  .pricing-details-section__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6px;
    gap: 12px;
  }
}
</style>
