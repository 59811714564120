<template>
  <div class="market-categories-listbox">
    <div
      v-for="option in options"
      :key="option.id"
      :class="[
        'market-categories-listbox__item',
        { 'market-categories-listbox__item--is-disabled': option.disabled },
      ]"
      @click="toggleMarketCategory(option)"
    >
      <Checkbox
        :model-value="modelValue[option.id]"
        @update:model-value="toggleMarketCategory(option)"
        :disabled="option.disabled"
      />
      {{ option.label }}
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/common/Checkbox';

export default {
  components: {
    Checkbox,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  emits: {
    'update:modelValue': {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const toggleMarketCategory = (option) => {
      if (option.disabled) return;
      emit('update:modelValue', {
        ...props.modelValue,
        [option.id]: !props.modelValue[option.id],
      });
    };

    return {
      toggleMarketCategory,
    };
  },
};
</script>

<style lang="scss">
.market-categories-listbox {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 4px;

  &__item {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #191414;
    padding: 8px;
    cursor: pointer;
    user-select: none;

    &--is-disabled {
      cursor: default;
    }
  }
}
</style>
