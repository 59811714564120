<template>
  <div class="pricing-details">
    <div class="pricing-details__head">
      <Heading
        as="h3"
        size="xs"
      >
        Pricing details
      </Heading>

      <TextInput
        v-if="eventMarkets?.length"
        v-model="search"
        class="pricing-details__search"
        icon="search"
        placeholder="Search markets"
        small
      />
    </div>

    <div
      v-if="eventMarkets?.length"
      class="market-groups"
    >
      <div
        :class="['market-group', {'selected': selectedMarketGroupCode === 'all'}]"
        @click="selectMarketGroup('all')"
      >
        All
      </div>
      <div
        :class="['market-group', {'selected': selectedMarketGroupCode === marketGroup.code}]"
        v-for="(marketGroup, index) in marketGroups"
        :key="index"
        @click="selectMarketGroup(marketGroup.code)"
      >
        {{ marketGroup.displayName }}
      </div>
    </div>

    <div
      v-if="isPlayerPropsSelected"
      class="team-filter"
    >
      <Dropdown :label="appliedTeamFilter.label">
        <DropdownItem
          v-for="team in teamFilterData"
          :key="team.team"
          clickable
          @click="applyTeamFilter(team)"
        >
          {{ team.label }}
        </DropdownItem>
      </Dropdown>
    </div>

    <div
      v-if="eventMarkets?.length"
      class="pricing-details__body"
    >
      <PricingDetailsSection
        v-for="(marketDisplay, marketDisplayIndex) in marketDisplayConfigurations"
        :key="marketDisplayIndex"
        :market-display-configuration="marketDisplay"
        :markets="marketDisplay.markets"
        :sport="sport"
        :competition="competition"
        :teams="teams"
        :is-usa-view="event.isUSAView"
        :all-market-parameters="allMarketParameters"
      />
    </div>
    <div
      v-else-if="marketsLoading"
      class="pricing-details__body"
    >
      <span class="pricing-details__message">
        Loading prices...
      </span>
    </div>
    <div
      v-else-if="!eventMarkets?.length"
      class="pricing-details__body"
    >
      <span class="pricing-details__message">
        No markets found.
      </span>
    </div>
    <div
      v-else
      class="pricing-details__body"
    >
      <span class="pricing-details__message">
        No prices available at this moment.
      </span>
    </div>
  </div>
</template>

<script>
import {
  toLower, trim, includes,
  filter, map, find, orderBy, isNil,
} from 'lodash';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import Heading from '@/components/common/Heading';
import TextInput from '@/components/common/TextInput';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';
import PricingDetailsSection from './PricingDetailsSection';

export default {
  components: {
    Heading,
    TextInput,
    PricingDetailsSection,
    Dropdown,
    DropdownItem,
  },
  props: {
    sportId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const search = ref('');
    const store = useStore();

    const sport = computed(() => store.getters.event.sport);
    const competition = computed(() => store.getters.event.competition);

    const event = computed(() => store.getters.event);
    const eventMarkets = computed(() => store.getters.eventMarkets);
    const eventDisplayMarkets = computed(() => orderBy(filter(store.getters.displayMarketsConfiguration,
      (market) => market.active), 'position'));
    const allMarketParameters = computed(() => store.getters.allMarketParameters(sport.value?.label || ''));
    const teams = computed(() => ({
      home: event.value.home,
      away: event.value.away,
    }));
    const teamFilterData = computed(() => [
      {
        team: -1,
        label: 'Both Teams',
      },
      {
        team: 0,
        label: event.value.home.name,
      },
      {
        team: 1,
        label: event.value.away.name,
      },
    ]);
    const marketGroups = computed(() => {
      let marketGroupsBySport = store.getters.marketGroupsBySportId(props.sportId);
      // NCAA events don't have periods so we remove them from the market group list
      if (event.value?.competition?.id === 'd1303850-9f46-4ef3-bc0d-11e0b8477d69') {
        marketGroupsBySport = filter(marketGroupsBySport, (marketGroup) => marketGroup.code !== 'PERIOD-BASKETBALL');
      }
      return filter(map(marketGroupsBySport, (marketGroup) => {
        let { displayName } = marketGroup;

        if (displayName === 'Period' && event.value.sport.label !== 'ICE_HOCKEY') {
          displayName = 'Quarter';
        }

        return {
          ...marketGroup,
          displayName,
        };
      }), (marketGroup) => find(eventMarkets.value, (market) => find(marketGroup.marketCodes.nodes, (code) => code.marketCode === market.marketCode)));
    });
    const appliedTeamFilter = ref(teamFilterData.value[0]);
    const isTeamSelected = computed(() => appliedTeamFilter.value.team !== -1);
    const selectedMarketGroupCode = ref('all');
    const isPlayerPropsSelected = computed(() => includes(selectedMarketGroupCode.value, '-PLAYER_PROPS'));
    const marketsLoading = computed(() => store.getters.eventMarketsLoading);

    const filteredMarkets = computed(() => {
      const searchValue = toLower(trim(search.value));
      const selectedMarketGroup = find(marketGroups.value, { code: selectedMarketGroupCode.value }) || { marketCodes: { nodes: [] } };
      const selectedMarketGroupCodes = map(selectedMarketGroup.marketCodes.nodes, (code) => code.marketCode);
      const markets = filter(eventMarkets.value,
        (market) => (includes(toLower(market.marketCode), searchValue)
          || includes(toLower(market.marketSummary?.playerName), searchValue)
          || (!isNil(market.marketType.params.TEAM) && includes(toLower(props.homeTeam?.name), searchValue))
          || (!isNil(market.marketType.params.TEAM) && includes(toLower(props.awayTeam?.name), searchValue))
          || includes(toLower(find(props.marketsDisplayConfiguration,
            (displayMarket) => displayMarket.marketCode === market.marketCode
              && displayMarket.selections === market.marketType.params.SELECTIONS)?.defaultName), searchValue))
          && (isPlayerPropsSelected.value && isTeamSelected.value ? market.marketType.params.TEAM === appliedTeamFilter.value.team : true)
          && (selectedMarketGroupCode.value === 'all' || includes(selectedMarketGroupCodes, market.marketCode)));
      return markets;
    });

    const marketDisplayConfigurations = computed(() => filter(map(eventDisplayMarkets.value, (marketConfiguration) => ({
      ...marketConfiguration,
      markets: map(filter(filteredMarkets.value, (market) => market.marketCode === marketConfiguration.marketCode
      && market.marketType.params.SELECTIONS === marketConfiguration.selections), (filterMarket) => filterMarket),
    })), (marketDisplayConfiguration) => marketDisplayConfiguration.markets.length));

    const applyTeamFilter = (team) => { appliedTeamFilter.value = team; };

    const selectMarketGroup = (marketGroupCode) => {
      if (marketGroupCode === selectedMarketGroupCode.value) return;
      selectedMarketGroupCode.value = marketGroupCode;
    };

    return {
      sport,
      competition,
      search,
      eventMarkets,
      marketsLoading,
      marketGroups,
      selectedMarketGroupCode,
      teamFilterData,
      appliedTeamFilter,
      isPlayerPropsSelected,
      teams,
      selectMarketGroup,
      applyTeamFilter,
      marketDisplayConfigurations,
      allMarketParameters,
      event,
    };
  },
};
</script>

<style lang="scss">
.pricing-details {
  flex: 1;
  background-color: #fff;
  padding: 16px;
  border: 1px solid #F0F0F0;
  box-sizing: border-box;
  border-radius: 4px;

  .button {
    stroke: #000;
  }

  .pricing-details__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;

    .pricing-details__title {
      color: #000000;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .pricing-details__body {
    margin-top: 24px;

    .pricing-details__message {
      font-size: 16px;
      color: #6D6D6D;
    }
  }

  .market-groups {
    display: flex;
    width: 100%;
    margin: 16px 0;
    flex-wrap: wrap;
    box-shadow: inset 0px -2px 0px #CDCDCD;

    .market-group {
      height: 44px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      color: #191414;
      box-shadow: inset 0px -2px 0px #CDCDCD;
      cursor: pointer;

      &.selected {
        box-shadow: inset 0px -2px 0px #003C3C;
      }
    }
  }
  .team-filter {
    .dropdown-button {
      width: 100%;
      justify-content: space-between;
    }
  }
}
</style>
