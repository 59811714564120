<template>
  <div
    :class="[
      'markets-list',
      { 'markets-list--horizontal': isHorizontal },
    ]"
  >
    <div class="markets-list__grid">
      <div
        v-if="markets?.[0]?.marketType?.params?.LINE || markets?.[0]?.marketType?.params?.LINE === 0"
        class="markets-list__grid-column"
      >
        <div class="markets-list__grid-cell markets-list__grid-cell--header">
          Line
        </div>
      </div>

      <div
        v-for="(selection, selectionIndex) in selections"
        :key="selectionIndex"
        class="markets-list__grid-column"
      >
        <div
          class="markets-list__grid-cell markets-list__grid-cell--header"
          :title="remapSelectionCode(selection)"
        >
          <span>{{ remapSelectionCode(selection) }}</span>
        </div>
      </div>
    </div>

    <div class="markets-list__grid">
      <div
        v-if="markets?.[0]?.marketType?.params?.LINE || markets?.[0]?.marketType?.params?.LINE === 0"
        class="markets-list__grid-column"
      >
        <div
          v-for="(market, marketIndex) in markets"
          :key="marketIndex"
          :class="['markets-list__grid-cell', { 'suspended': market.offeringStatus === 'SUSPENDED' }]"
        >
          {{ market.marketType.params.LINE }}
        </div>
      </div>

      <div
        v-for="(selection, selectionIndex) in selections"
        :key="selectionIndex"
        class="markets-list__grid-column"
      >
        <div
          v-for="(market, marketIndex) in markets"
          :key="marketIndex"
          :class="['markets-list__grid-cell', { 'suspended': market.offeringStatus === 'SUSPENDED' }]"
        >
          {{ getPriceBySelection(market.selections, selection, oddsFormat) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import MarketMapper from '@/services/helpers/market-mapper';
import { getPriceBySelection, setTeamNames } from './utils';

export default {
  props: {
    markets: {
      type: Array,
      required: true,
    },
    selections: {
      type: Array,
      required: true,
    },
    isHorizontal: {
      type: Boolean,
      default: false,
    },
    sport: {
      type: Object,
      required: true,
    },
    teams: {
      type: Object,
      default: null,
    },
    isUsaView: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const oddsFormat = computed(() => store.getters.selectedOddFormat?.name);
    const homeTeam = computed(() => props.teams?.home);
    const awayTeam = computed(() => props.teams?.away);

    const replaceTeamNames = (selectionLabel) => setTeamNames(selectionLabel, homeTeam.value, awayTeam.value);

    const remapSelectionCode = (selection) => {
      const selectionName = MarketMapper.selectionName(selection, props.sport, props.isUsaView);
      return replaceTeamNames(selectionName);
    };

    return {
      getPriceBySelection,
      remapSelectionCode,
      oddsFormat,
    };
  },
};
</script>
