<template>
  <div
    ref="rootElement"
    class="hierarchy-search"
  >
    <input
      class="hierarchy-search__field"
      type="text"
      :value="modelValue"
      :placeholder="'Search &quot;NBA&quot;, &quot;NFL&quot;...'"
      @input="onSearch($event.target.value)"
      @focus="openSearch"
    >
    <ul
      v-if="isMenuToggled"
      class="hierarchy-search__menu"
    >
      <li
        v-if="isLoading"
        class="hierarchy-search__message"
      >
        <Spinner
          small
        />
        Finding results...
      </li>
      <li
        v-else-if="!allSearchResults.length"
        class="hierarchy-search__message"
      >
        No results found.
      </li>
      <li
        v-for="searchResult in allSearchResults"
        :key="searchResult.competitionId"
        class="hierarchy-search__item"
        @click="selectCompetition(searchResult)"
      >
        <div class="hierarchy-search__competition">
          <div class="hierarchy-search__competition-metadata">
            <span
              class="hierarchy-search__competition-link"
              :title="searchResult.sport?.sportName || 'N/A'"
              @click.stop="selectSport(searchResult)"
            >
              {{ searchResult.sport?.sportName || 'N/A' }}
            </span>
            &sol;
            <span
              class="hierarchy-search__competition-link"
              :title="searchResult.region?.regionName || 'N/A'"
              @click.stop="selectRegion(searchResult)"
            >
              {{ searchResult.region?.regionName || 'N/A' }}
            </span>
          </div>
          <div
            class="hierarchy-search__competition-label"
            :title="searchResult.competitionName || 'N/A'"
          >
            {{ searchResult.competitionName || 'N/A' }}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref } from 'vue';
import { debounce } from 'lodash';
import { onClickOutside } from '@vueuse/core';
import { loadSearchResults } from '@/services/helpers/hierarchy-sidebar';
import Spinner from '@/components/common/Spinner';

export default {
  components: {
    Spinner,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  emits: {
    'update:modelValue': {
      type: String,
    },
    optionSelected: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const rootElement = ref(null);
    const isMenuToggled = ref(false);
    const isLoading = ref(false);
    const allSearchResults = ref([]);

    const search = () => {
      isLoading.value = true;
      allSearchResults.value = [];
      return loadSearchResults(props.modelValue)
        .then((payload) => {
          allSearchResults.value = payload;
        })
        .catch((error) => {
          console.error(error);
          allSearchResults.value = [];
        })
        .finally(() => {
          isLoading.value = false;
        });
    };
    const debouncedSearch = debounce(search, 300);
    const onSearch = (newSearchQuery) => {
      emit('update:modelValue', newSearchQuery);
      debouncedSearch();
    };
    const openSearch = () => {
      isMenuToggled.value = true;
      search();
    };
    const closeSearch = () => {
      isMenuToggled.value = false;
      emit('update:modelValue', null);
      emit('update:modelValue', '');
      allSearchResults.value = [];
    };
    const selectOption = (options) => {
      emit('optionSelected', options);
      closeSearch();
    };
    const selectSport = (searchResult) => {
      selectOption({
        sportId: searchResult.sport.sportId,
        regionId: '',
        competitionId: '',
      });
    };
    const selectRegion = (searchResult) => {
      selectOption({
        sportId: searchResult.sport.sportId,
        regionId: searchResult.region.regionId,
        competitionId: '',
      });
    };
    const selectCompetition = (searchResult) => {
      selectOption({
        sportId: searchResult.sport.sportId,
        regionId: '',
        competitionId: searchResult.competitionId,
      });
    };

    onClickOutside(rootElement, closeSearch);

    return {
      rootElement,
      isMenuToggled,
      isLoading,
      allSearchResults,
      onSearch,
      openSearch,
      closeSearch,
      selectSport,
      selectRegion,
      selectCompetition,
    };
  },
};
</script>

<style lang="scss">
.hierarchy-search {
  position: relative;

  &__field {
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    background-color: #fff;
    border: 1px solid #F0F0F0;
    width: 100%;
    height: 32px;
    padding: 8px;
    border-radius: 4px;

    &::placeholder {
      color: #CDCDCD;
    }

    &:focus {
      box-shadow: 0px 0px 0px 2px #A2BFFF;
      outline: none;
    }
  }

  &__menu {
    position: absolute;
    top: calc(100% + 4px);
    background-color: #fff;
    color: #191414;
    box-shadow: 0 4px 8px rgba(0,0,0,.08);
    border-radius: 4px;
    margin-bottom: 4px;
    padding: 4px 0;
    border: 1px solid #f0f0f0;
    width: 100%;
    max-height: 454px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    z-index: 1000;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    white-space: nowrap;
    cursor: pointer;
    border-bottom: 1px solid #F0F0F0;

    &:hover {
      background-color: #fafafa;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  &__message {
    display: flex;
    align-items: center;
    padding: 8px;
    gap: 8px;
    background: var(--neutral-bg-default-resting, #FFF);
    overflow: hidden;
    color: var(--neutral-text-default-resting, #191414);
    text-overflow: ellipsis;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    white-space: nowrap;
    width: 100%;
    flex: 1;
    cursor: default;
  }

  &__competition {
    width: 100%;

    &-metadata {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &-link {
      border: none;
      color: #175FFF;

      &:first-of-type {
        flex-shrink: 0;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    &-label {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
</style>
